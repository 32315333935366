





































































import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import moment from 'moment'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import markdownIt from "markdown-it"
require("moment-duration-format")

@Component({
  components: {
    ClipLoader,
  },
})
export default class News extends Vue {
  isLoading = true
  md = new markdownIt({html: false, breaks: true})
  thisNews: any = {}
  news: any[] = []

  mounted() {
    let promises: any = []

    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('news').doc(this.$route.params.id).get().then((data) => {
        this.thisNews = data.data()
        let createTime = this.thisNews.createTime.toDate()
        this.thisNews.createTime = moment(createTime.getFullYear() > 2100 ? this.thisNews.updateTime.toDate() : createTime).format('YYYY/MM/DD HH:mm') 
        resolve()
      })
    }))

    promises.push(new Promise((resolve) => {
      let newsCollection = firebase.firestore().collection('news').where('public', '==', true)
      if (!Common.getUserId()) {
        newsCollection = newsCollection.where('onlyLogin', '==', false)
      }
      newsCollection.orderBy('createTime', 'desc').limit(5).get().then((data) => {
        data.docs.forEach((news) => {
          let obj = news.data()
          obj.id = news.id
          let createTime = obj.createTime.toDate()
          obj.createTime = moment(createTime.getFullYear() > 2100 ? obj.updateTime.toDate() : createTime).format('YYYY/MM/DD') 
          this.news.push(obj)
        })
        resolve()
      })
    }))

    Promise.all(promises)
      .finally(() => {
        this.isLoading = false
      })
  }

  select(id: string) {
    this.thisNews = this.news.find((value) => {
      return value.id == id
    })
  }

  compiledMarkdownText() {
    return this.thisNews.text ? this.md.render(this.thisNews.text) : ''
  }
}
